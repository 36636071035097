.container {
    padding: 10px;
    position: relative;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
}

.catalog_title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    color: black;
    margin-bottom: 20px;
}

.stories_block {
    margin-bottom: 14px;
}

.catalog {
    margin-bottom: 20px;
}

.button_go {
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    background-color: white;
    margin-bottom: 10px;
}

.f_block {
    display: flex;
    align-items: center;
    gap:10px;
}
.f_block_title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.f_block_value {
    font-size: 13px;
    font-weight: 400;
}

.my_orders {
    height: 46px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: white;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}
.container {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: stretch;
    gap:10px;
    justify-content: space-between;
}

.balance_block {
    border-radius: 25px;
    height: 232px;
    background-color: #a2217d;
    width: 50%;
    display: flex;
    padding: 10px 15px;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
}

.balance_img {
    height: 70px;
    width: 70px;
}

.balance_text {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 2px;
    color: white;
}

.balance_value {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    color: black;
}

.other_blocks {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap:10px;
}

.other_block:first-child {
    margin-bottom: 6px;
}

.other_block {
    display: flex;
    padding: 10px 16px;
    text-decoration: none;
    height: 111px;
    justify-content: space-between;
}

.block_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.other_block {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 25px;
}

.block_text {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: black;

}

.block_value {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: black;

}
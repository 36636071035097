@import '@telegram-apps/telegram-ui/dist/styles.css';


.light {
    --tg-viewport-height: 100vh !important;
    --tg-viewport-stable-height: 100vh !important;
    --tgui--accent_text_color: #007aff !important;
    --tgui--base--section--box_shadow: 0 1px 2px 0 rgba(0,0,0,0.1) !important;
    --tgui--bg_color: #fff !important;
    --tgui--black: #000 !important;
    --tgui--border--width: 0.5px !important;
    --tgui--button_color: #007aff !important;
    --tgui--button_text_color: #fff !important;
    --tgui--caption1--font_size: 13px !important;
    --tgui--caption1--line_height: 16px !important;
    --tgui--caption2--font_size: 11px !important;
    --tgui--caption2--line_height: 13px !important;
    --tgui--card_bg_color: #fff !important;
    --tgui--destructive_background: #e53935 !important;
    --tgui--destructive_text_color: #e53935 !important;
    --tgui--divider: rgba(0,0,0,0.15) !important;
    --tgui--font_weight--accent1: 700 !important;
    --tgui--font_weight--accent2: 600 !important;
    --tgui--font_weight--accent3: 400 !important;
    --tgui--font-family: system-ui,-apple-system,BlinkMacSystemFont,"Roboto","Apple Color Emoji","Helvetica Neue",sans-serif !important;
    --tgui--green: #31d158 !important;
    --tgui--header_bg_color: #fff !important;
    --tgui--headline--font_size: 19px !important;
    --tgui--headline--line_height: 24px !important;
    --tgui--hint_color: #707579 !important;
    --tgui--large_title--font_size: 34px !important;
    --tgui--large_title--line_height: 41px !important;
    --tgui--link_color: #007aff !important;
    --tgui--outline: rgba(0,0,0,0.05) !important;
    --tgui--plain_background: rgba(0,0,0,0.04) !important;
    --tgui--plain_foreground: rgba(0,0,0,0.8) !important;
    --tgui--primary_code_highlight: #4378ff !important;
    --tgui--quartenary_bg_color: #f6f6fa !important;
    --tgui--safe_area_inset_bottom: 0px !important;
    --tgui--secondary_bg_color: #efeff4 !important;
    --tgui--secondary_code_highlight: #b00fb4 !important;
    --tgui--secondary_fill: #007affcolor-mix(in srgb,#007aff 10%,transparent) !important;
    --tgui--secondary_hint_color: #a2acb0 !important;
    --tgui--section_header_text_color: #707579 !important;
    --tgui--segmented_control_active_bg: #fff !important;
    --tgui--skeleton: hsla(0,0%,100%,0.03) !important;
    --tgui--subheadline1--font_size: 16px !important;
    --tgui--subheadline1--line_height: 21px !important;
    --tgui--subheadline2--font_size: 15px !important;
    --tgui--subheadline2--line_height: 20px !important;
    --tgui--subtitle_text_color: #707579 !important;
    --tgui--surface_dark: rgba(56,56,56,0.85) !important;
    --tgui--surface_primary: hsla(0,0%,100%,0.95) !important;
    --tgui--tertiary_bg_color: #f4f4f7 !important;
    --tgui--tertiary_code_highlight: #3a9f20 !important;
    --tgui--text_color: black !important;
    --tgui--text--font_size: 17px !important;
    --tgui--text--line_height: 22px !important;
    --tgui--title1--font_size: 28px !important;
    --tgui--title1--line_height: 34px !important;
    --tgui--title2--font_size: 24px !important;
    --tgui--title2--line_height: 28px !important;
    --tgui--title3--font_size: 20px !important;
    --tgui--title3--line_height: 25px !important;
    --tgui--toast_accent_color: #55a6ff !important;
    --tgui--white: #fff !important;
    --tgui--z-index--overlay: 3 !important;
    --tgui--z-index--simple: 1 !important;
    --tgui--z-index--skeleton: 2 !important;
    --tooltip_background_dark: rgba(0,0,0,0.85) !important;
    --tgui--section_bg_color: white !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.hint {
    color: var(--tgui--hint_color);
    font-size: 15px;
}

.section__header {
    margin-bottom: 8px !important;
    padding: 0px 0px 0px 12px !important;
}


.tgui-b8dfba0b5c3d054c:hover {
    background: var(--tgui--bg_color) !important;
    border-radius: 12px;
}

.tgui-b8dfba0b5c3d054c {
  background: var(--tgui--bg_color) !important;
  border-radius: 12px;
}

.tgui-0f5050defacbf813, .file__picker {
    background: rgb(240, 240, 240) !important;
    border-radius: 12px;
}
.cell__section{
    background: rgb(240, 240, 240) !important;
    padding: 5px 16px;
    margin-top: 20px;
}
.cell__section:hover {
    background: rgb(240, 240, 240) !important;
}
.section {
    background-color: rgb(240, 240, 240) !important;
    overflow: hidden;
    border-radius: 16px;
}
.conditions_ol {
    background-color: rgb(240, 240, 240) !important;
    overflow: hidden;
    border-radius: 16px;
    padding: 12px 16px 12px 28px;
    margin: 16px 0 !important;
}
.cell {
    border-radius: 0px;
}
.cell:hover {
    background: none !important;
    border-radius: 0px !important;
}
.cell__red {
    color: red;
}

.file__picker .tgui-c3e2e598bd70eee6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file__picker svg {
    min-width: 26px;
}

.tgui-4b78bed6e925088e {
    overflow: hidden;
}

body {
    min-height: calc(100vh + 1px);
    overflow-y: auto;
    background-color: white;
}

.app {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    min-height: 100vh;
    background-color: white;
    color: var(--tgui--text_color) !important;
}

.container {
    padding: 16px;
    padding-bottom: 50px;
    /*background-color: rgb(249, 249, 249);*/
    min-height: calc(100vh);
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header h1 {
    letter-spacing: -0.01em;
    font-size: 22px;
    color: black;
    font-weight: 600;
}

.header div {
    color: #731358;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
}

@media screen and (max-width: 390px) {
    .header h1 {
        font-size: 18px !important;
    }

    .header div {
        font-size: 0.6rem !important;
    }
}

.search_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;

}

.input_search {
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    display: flex;
    outline: none;
    height: 40px;
    -webkit-box-flex: 1;
    cursor: text;
    font-size: 24px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0.5rem;
    background-color: white;
    padding: 0 8px;
    width: 100%;
    margin-right: 8px;
    border: 1px solid lightgray;
    color: #969DA6;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 75ms;
}

.search_icon {
    min-width: 36px;
    height: 36px;
    display: flex;
    cursor: pointer;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
}

.search_icon svg {
    fill: #464B53;
    stroke: #464B53;
    width: 20px;
    height: 20px;
    transition: transform 0.15s ease 0s;
}

.active_input {
    border: 2px solid #731358;
}

.input_search input {
    width: 100%;
    flex-shrink: 1;
    line-height: 1;
    color: #464B53;
    outline: none;
    border: none;
    height: 100%;
}

.close_icon {
    min-width: 24px;
    height: 24px;
    background-color: rgba(5, 26, 46, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.close_icon:hover {
    background-color: rgb(197, 197, 197) !important;
}

.close_icon svg {
    width: 20px;
    fill: lightgray;
    height: 20px;
}

.filter_search {
    min-width: 40px;
    height: 40px;
    border: 1px solid lightgray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
}

.filter_search svg {
    width: 20px;
    fill: lightgray;
    stroke: lightgray;
    height: 20px;

}

.catalog {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
}

.catalog .cart {
    cursor: pointer;
    width: 48%;
}

.catalog .cart img {
    width: 100%;
    aspect-ratio: 1/1.4;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 10px;
}

.catalog .cart .info {
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    color: #25282D;
}

.catalog .cart .info .price {
    overflow-wrap: break-word;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1rem;
    color: #731358;
}

.catalog .cart .info .title {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #5D646F;
}

.buttonTo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 42px;
    background-color: #731358;
    color: #fff;
    z-index: 10;
    white-space: nowrap;
    line-height: 1.5rem;
    margin-bottom: 16px;
    border-radius: 10px;
    cursor: pointer;
}

.buttonTo div {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.buttonTo div svg {
    width: 20px;
    height: 20px;
}

.title_page {
    height: 48px;
    font-size: 17px;
    position: sticky;
    z-index: 1000;
    background-color: white;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title_page div {
    /* width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.hint_block {
    width: 100%;
    background-color: rgba(115, 19, 88, 0.2);
    padding: 12px 16px;
    margin-top: 16px;
    user-select: none;
    border-radius: 10px;
    border-color: rgba(115, 19, 88, 0.2);
    color: #25282D;
}

.hint_block_gray {
    width: 100%;
    background-color: rgba(5, 26, 46, 0.1);
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 16px;
    border-radius: 10px;
    border-color: rgba(255, 255, 255, 0.1);
    color: #25282D;
}

.hint_block_gray img {
    align-self: flex-start;
}

.catalog_v2 {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;

    background-color: white;
    overflow: hidden;

}

.cart_v2 {
    width: 100%;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.cart_v2 img {
    margin-right: 16px;
    min-width: 48px;
    aspect-ratio: 1/1;
    align-self: flex-start;
    max-height: 48px;
    object-fit: cover;
    border-radius: 10px;
}

.cart_v2:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

.cart_v2 .text {
    width: 100%;
}

.cart_v2 .good_id {
    font-weight: 600;
    text-transform: uppercase;
    color: #731358;
    font-size: 12px;
    line-height: 20px;
}

.cart_v2 .title_v2 {
    white-space: pre-wrap;
    font-size: 0.9375rem;
    font-weight: 600;
    color: var(--gv-text-contextual-dark);
}

.cart_v2 .price_v2 {
    white-space: pre-wrap;
    font-size: 0.875rem;
    color: #5D646F;
}

.cart_v2 .arrow_icon {
    align-self: center;
    width: 28px;
    height: 28px;
}

.good_img {
    width: 100%;
    height: auto;
}

.good_title {
    margin: 12px 0;
    line-height: 1.2;
    letter-spacing: -0.01em;
    font-size: 19px;
    font-weight: 600;
}

.inforamtion_ship {
    width: 100%;
}

.inforamtion:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

.inforamtion {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.inforamtion_title {
    font-size: 0.9375rem;
    line-height: 1.2;
    color: #7E8691;
}

.inforamtion_value {
    font-size: 0.9375rem;
    line-height: 1.2;
    color: #25282D;
}

.button_ship {
    margin-top: 16px;
    background-color: #731358;
    position: relative;
    z-index: 10;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5rem;
    cursor: pointer;
    padding: 8px 23px;
    width: 100%;
    text-align: center;
    color: white;
}

.filter {
    position: fixed;
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 600px;
    height: 100vh;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
}

.filter_block {
    height: 75vh;
    width: 100%;
    position: relative;
    background-color: white;
    animation: opacity 0.5s ease-in-out;
    padding: 20px;
    border-radius: 15px 15px 0 0;
}

.filter_overflow {
    height: calc(100% - 70px);
    width: 100%;
    overflow-y: auto;
}

.filters_buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    display: flex;
    justify-content: center;
    gap: 12px;
    background-color: white;
    border-top: 1px solid lightgray;
}

.filter_info {
    padding: 16px 0;
}

.filters_buttons div {
    padding: 6px 16px;
    cursor: pointer;
}

.filters_buttons div:last-child {
    position: relative;
    z-index: 10;
    overflow: hidden;
    background-color: #731358;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5rem;
    color: white;
    border-radius: 10px;
    font-weight: bold;
}

.filters_buttons div:first-child {
    position: relative;
    z-index: 10;
    overflow: hidden;
    border: 1px solid lightgray;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5rem;
    color: black;
    border-radius: 10px;
}


.close_animation {
    animation: close 0.21s ease-in-out;
}

@keyframes close {
    0% {
        bottom: 0;
        opacity: 1;
    }

    100% {
        bottom: -100%;
        opacity: 0;
    }
}

@keyframes opacity {
    0% {
        bottom: -100%;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

.overflow {
    overflow-y: hidden;
    height: calc(100vh - 48px);
}

.close_icon_filter {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
}

.filter_block .filter_title {
    text-align: center;
}


.filter_info:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

.filter_info_title {
    font-size: 0.9375rem;
    line-height: 1.4;
    display: flex;
    letter-spacing: -0.01em;
    font-weight: 600;
    color: #464B53;
}

.filter_info_option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.filter_info_option div {
    font-size: 0.9375rem;
    line-height: 1.4;
    color: #464B53;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-checkbox+span {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.custom-checkbox+span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-checkbox:checked+span::before {
    border-color: #731358;
    background-color: #731358;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}



.input_question {
    width: 100%;
}

.input_question_block {
    margin: 16px 0;
}
.input_question textarea {
    width: 100%;
    height: 150px;
    color: rgb(136, 136, 136);
    padding: 12px 8px;
    background-color: white;
    border: 1px solid lightgray;
    outline: none;
    border-radius: 10px;
    font-size: 19px;
    resize: none;
}
.input_question input,
.input_question select {
    width: 100%;
    height: 46px;
    color: rgb(136, 136, 136);
    font-size: 19px;
    padding: 12px 8px;
    background-color: white;
    border: 1px solid lightgray;
    outline: none;
    border-radius: 10px;
}

.image_picker {
    width: 100%;
    height: 40px;
    padding: 8px;
    background-color: white;
    border: 1px solid lightgray;
    outline: none;
    border-radius: 10px;
    color: rgb(136, 136, 136);
    font-size: 13px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.input_question input:focus,
.input_question select:focus {
    border: 2px solid #731358;
}

.input_question>div:first-child {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    flex-shrink: 1;
    color: #25282D;
    justify-content: space-between;
}

#editorjs {
    height: 200px;
}

.require_question {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
    color: #5D646F;
}

.input_brand {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input_brand .brand_question {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    color: #25282D;
}

.create_good {
    overflow-y: auto;
    padding-bottom: 200px;
}

.create_post {
    border-radius: 10px;
    height: fit-content !important;
    min-height: unset !important;
    padding-bottom: 0px;
    overflow-y: unset;
}

.image_select {
    width: 28px;
    height: 28px;
    object-fit: cover;
    margin-right: 5px;
    border-radius: 10px;
}

.image_picker_close {
    width: 16px;
    background-color: gray;
    border-radius: 100%;
    height: 16px;
}

.image_picker_close svg {
    fill: white;
    stroke: white;
}

.image_picker_info {
    display: flex;
    max-height: 100%;
    align-items: center;
    overflow: hidden;
}

.image_select {
    align-self: center;
}

.text_file_image {
    max-height: 100%;
}

.hint_block_warn {
    background-color: #FCF7E8;
    padding: 12px 16px;
    color: #E18F05;
    border-radius: 10px;
    border: 1px solid #F7D98B;
}

.conditions_title {
    margin: 5px 0;
    line-height: 1.2;
    letter-spacing: -0.01em;
    font-size: 22px;
    font-weight: bold;
    overflow-wrap: break-word;
    color: #25282D;
}

.conditions_ol {
    font-size: 0.9375rem;
    line-height: 1.4;
    color: rgb(70, 75, 83);
    margin-left: 20px;
}

.conditions_ol_te {
    font-size: 0.9375rem;
    line-height: 1.4;
    color: rgb(70, 75, 83);
}

.description_good {
    color: rgb(70, 75, 83);
    font-size: 0.9375rem;
    line-height: 1.4;
    margin-top: 10px;
}

.conditions_accept {
    display: flex;
    align-items: center;
    margin: 16px 0;
    font-weight: 400;
    font-size: 0.875rem;
    color: #25282D;
}

.deal_b {
    font-size: inherit;
    line-height: 1.4;
    color: rgb(70, 75, 83);
}

.filters_button_deal {
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 42px;
    align-items: center;
    gap: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5rem;
    border-radius: 10px;
    cursor: pointer;
}

.filters_button_deal svg {
    width: 24px;
    height: 24px;
}

.filters_buttons_deal {
    width: 100%;
    padding: 20px 0;
    margin-top: 20px;
    border-top: 1px solid lightgray;
}

.deal_load {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #25282D;
}

.tapbar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.tapbar div {
    width: fit-content;
    cursor: pointer;
    text-align: center;
}

.active_tapbar {
    color: #731358;

}

.textarea_input:focus {
    border: 2px solid #731358;
}

.textarea_input {
    height: 80px;
    width: 100%;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 8px;
    resize: none;
}

.go2072408551 {
    z-index: 1000000;
    background-color: white;
}

.wildberrieslink {
    color: #731358;
    padding: 5px 8px;
    background-color: rgba(115, 19, 88, 0.07);
    border-radius: 10px;
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.image_cart_task {
    line-height: 1.2;
    letter-spacing: -0.01em;
    font-size: 22px;
    font-weight: 500;
    margin: 20px 0 10px 0;
}

.image_task {
    width: 100%;
    object-fit: cover;
    max-height: 800px;
    margin-top: 16px;
    border-radius: 12px;
}

.image_task_result {
    width: 100%;
    object-fit: cover;
    max-height: 1000px;
    margin-bottom: 16px;
    border-radius: 12px;
}

.loader_block {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader_block img {
    width: 60px;
    height: 60px;
}

.main_container {
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
}

.select_filters_count {
    margin-left: 8px;
    display: flex;
    height: 24px;
    width: 24px;
    align-items: center;
    color: #731358;
    background-color: rgba(115, 19, 88, 0.1);
    justify-content: center;
    border-radius: 0.5rem;
}

.progress-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    border-radius: 10px;
}

.progress {
    width: 100%;
    height: 3px;
    border-radius: 10px;
}

.progress::-webkit-progress-value {
    background: #731358;
}

.img_res {
    width: 70px;
    max-height: 70px !important;
}

.res_text {
    align-self: flex-start;
}

.reload {
    color: #731358;
    cursor: pointer;
    padding: 5px 0px;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
}

.area {
    width: 100%;
    height: 140px;
    color: rgb(136, 136, 136);
    padding: 12px 8px;
    background-color: white;
    border: 1px solid lightgray;
    outline: none;
    border-radius: 10px;
    font-size: 18px;
    color: black;
    resize: none;
}

.area:focus {
    border: 2px solid #731358;
}

.comm {
    background-color: #0088cc;
    display: flex;
    align-items: center;
    gap: 10px;
}

.tg__color {
    color: #0088cc !important;
}

.icon_tg {
    width: 26px;
    height: 26px;
}

.price_catalog {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow-wrap: break-word;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1rem;
    color: #731358;
    margin-bottom: 3px;
}

.price_catalog_wb {
    font-size: 0.85rem;
    color: gray;
    text-decoration: line-through;
}

.buttonTo__notactive {
    background-color: lightgray;
}




/* src/components/helpers/modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 10000000;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 320px;

}

.modal-content-text {
    margin-top: 10px;
}

.modal-actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: end;
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}



.modal-button-confirm {
    background-color: #0088cc;
    color: white;
}

.pretitle__orders {
    font-size: 19px;
}

.pretitle__orders_buttons {
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap:10px;
    padding: 8px 0;
}

.orders_button {
    border: 1px solid gray;
    color: black;
    white-space: nowrap;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 20px;
    width: fit-content;
}

.active__orders_button {
    color: #731358;
    padding: 5px 10px;
    width: fit-content;
    white-space: nowrap;
    border-radius: 20px;
    background-color: #fbace5;
    border: 1px solid #731358;
}

.wrohgs {
    padding: 10px;
    width: 100%;
    background-color: rgb(232, 232, 232);
    color:#0088cc;
    border-radius: 10px;
    position: fixed;
    bottom: 10px;
    margin: 0 16px;
    left:0;
    width: calc(100% - 32px);
    right:16px;
}

.no_wrap {
    white-space: wrap !important;
}

.addition__goods {
    color:#731358;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    cursor: pointer;
}

.offer__block__info {
    display: flex;
    align-items: start;
    gap:30px;
}

.offer__block__img {
    width: 200px;
    aspect-ratio: 1/1;
    border-radius: 15px;
    object-fit: cover;
}

.offer__block__article {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
}
.offer__block__name {
    color: gray;
    font-size: 18px;
    font-weight: 400;
}

.recharts-legend-wrapper {
    display: none;
}

.recharts-wrapper {
    width: 100% !important;
}

.ref__link {
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    display: flex;
    outline: none;
    height: 40px;
    -webkit-box-flex: 1;
    cursor: text;
    font-size: 20px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0.5rem;
    background-color: white;
    padding: 0 8px;
    width: 100%;
    margin-right: 8px;
    border: 1px solid lightgray;
    color: black;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 75ms;
}

.invite__links {
    display: flex;
    align-items: center;
    gap:16px;
    justify-content: space-between;
    margin: 10px 0;
}

.invite__link img {
    width: 20px;
    height: 20px;
}

.invite__link {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    background-color: #0088cc;
    justify-content: center;
    border-radius: 100%;
}

.invite__block {
    width: 100%;
    background-color: #0088cc;
    color:white;
    border-radius: 30px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.earn__page {
    padding: 15px 10px;
    border-radius: 0 0 15px 15px;
}

.earn__page_t {
    text-align: center;
    font-size: 24px;
    margin-bottom:15px;
}

.earn__page_d {
    color: #5D646F;

}

.earn__page_d span {
    font-weight: bold;
    color:black;
}

.channel__page {
    height: 99vh;
    width: 100%;
    display: flex;
    position: relative;
    align-items: start;
    gap:10px;
    flex-direction: column;
    padding: 16px 10px;
}
.channel__page > div {
    width: 100%;
}
.channel {
    border-radius: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: rgb(244, 244, 244);
    margin-bottom: 10px;

}

.channel__block {
    display: flex;
    align-items: center;
    gap:10px;
}
.channel__text_t {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3px;
}

.channel__text_v {
    font-size: 15px;
    font-weight: 400;
}
.channel__info {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
    border:1px solid black;
}

.price__list {
    width: 100%;
    background-color: rgb(244, 244, 244);
    border-radius: 10px;
    padding: 10px;
    font-size: 18px;
}

.channel__button {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px) !important; 
}

.create_post {
    width: 100vw;
    background-color: transparent;
    top:0px;
}

.bloger__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.tabs {
    padding: 5px 10px;
}

.good_page__catalog {
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    background-color: white;
    z-index: 10000000000;
}

.cell {
    background-color: rgb(243, 243, 243) !important;
}

.cell:hover {
    background-color: rgb(243, 243, 243) !important;
}

.green > *{
    color: #31d158 !important;
}

.red > *{
    color: #d13131 !important;
}

.delete_order {
    border: 1px solid #d13131 !important;
    color: #d13131 !important;
}


.video__iframe {
    border-radius: 10px;
    width: 100%;
    height: 300px;
    padding: 5px 0;
    margin-top: 20px;
}

input[readonly] {
    user-select: none;
    pointer-events: none; 
}

.loader__deal__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:30px;
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left:0;
    height: 100vh;
    color: rgb(134, 134, 134);
    overflow: hidden;
    z-index: 100000000000000000000;
    width: 100%;
    backdrop-filter: blur(8px);
}

.loader__deal {
    width: 200px !important;
    height: 200px;
}

.order__accept__button {
    margin-top: 10px;
}

.order__404 {
    display: flex;
    padding: 16px 12px;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: calc(100vh);
}
.container {
    display:flex;
    margin:0;
    padding:0;
    align-items: center;
    min-width:100%;
    overflow: auto hidden;
   
    
}
.container_item {
   width: 82px;
   height: 82px;
}

.container_item:not(:last-child) {
    margin-right:8px;
}
/*
.container_item {
    position: relative;
}
.story {
    width:115px;
    height:115px;
    padding: 4px;
    border-radius: 20px;
    border:1px solid black;
}
.container_item:not(:last-child) {
    margin-right:8px;
}

.text {
    top:12px;
    right:12px;
    position: absolute;
 
    font-size:12px;
    left:12px;
    color:white;
}

.color {
    width: 100%;
    height: 100%;
    background-color: gray;
    border-radius: 20px;
}

.color_black {
    width: 100%;
    height: 100%;
    background-color: black;
    border-radius: 20px;
}*/